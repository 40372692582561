<template>
    <LinceRealtimeSystem
        :componentKey="componentKey"
        :socketHost="socketHost"
        :socketPort="socketPort"
        :apiHost="apiHost"
        :apiAuthRoute="apiAuthRoute"
        :apiAuthToken="apiAuthToken"
        :apiShareToken="apiShareToken"
        :isShareToken="isShareToken"
        :onAnyEventsSocketScheme="onAnyEventsSocketScheme"
        v-on="$listeners"
    >
        <slot />
    </LinceRealtimeSystem>
</template>

<script>
import ServiceToken from '../../services/ServicesAuth/ServiceToken';
export default {
    name: 'RealtimeSystem',
    props: {
        componentKey: {
            type: String,
            default: ''
        },
        onAnyEventsSocketScheme: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        socketHost: process.env.VUE_APP_SOCKET_HOST,
		socketPort: process.env.VUE_APP_SOCKET_PORT,
		apiHost: process.env.VUE_APP_FREE_BACKEND_HOST,
		apiAuthRoute: '/socketServer/auth',
		// apiAuthToken: ServiceToken.productToken,
    }),
    computed: {
        isShareToken() {
            return window.serviceShare !== null && typeof(window.serviceShare) !== 'undefined';
        },
        apiAuthToken() {
            return this.isShareToken ? window.serviceShare.shareProductToken : ServiceToken.productToken;
        },
        apiShareToken() {
            return this.isShareToken ? window.serviceShare.shareToken : '';
        }
    }
}
</script>