<template>
    <div class="reconciliation-filter">
        <template v-if="isLoading">
            <div
                v-if="isTopFIltersVisible"
                class="reconciliation-filter__items"
            >
                <ReconciliationFilterItem
                    :isLoading="isLoading"
                    :style="{ flex: 0.1 }"
                    filterItemComponentName="BaseTextInput"
                    class="reconciliation-filter__item reconciliation-filter__item_shadow"
                />
                <ReconciliationFilterItem
                    :isLoading="isLoading"
                    :style="{ flex: 0.3 }"
                    filterItemComponentName="BaseTextInput"
                    class="reconciliation-filter__item reconciliation-filter__item_shadow"
                />
            </div>
            <div class="reconciliation-filter__items">
                <ReconciliationFilterItem
                    v-for="(_, index) in filterItemsShadow"
                    :key="index"
                    :isLoading="isLoading"
                    filterItemComponentName="BaseSelectMultipleV2"
                    class="reconciliation-filter__item reconciliation-filter__item_shadow"
                />
            </div>
        </template>
        <template v-else>
            <div class="reconciliation-filter__items">
                <ReconciliationFilterItem
                    v-for="(filterItem, index) in filterItemsFirstRow"
                    :key="index"
                    :filterItemId="getFilterItemId(filterItem)"
                    :filterItemComponentName="getFilterItemComponentName(filterItem)"
                    :filterItemValues="getFilterItemValues(filterItem)"
                    :filterItemValuesSelected="getFilterItemValuesSelected(filterItem)"
                    :filterItemDefaultTitle="getFilterItemDefaultTitle(filterItem)"
                    :style="getFilterItemStyle(filterItem)"
                    class="reconciliation-filter__item"
                    @onSelectFilterItem="onSelectFilterItem"
                />
            </div>
            <div class="reconciliation-filter__items">
                <ReconciliationFilterItem
                    v-for="(filterItem, index) in filterItemsSecondRow"
                    :key="index"
                    :filterItemId="getFilterItemId(filterItem)"
                    :filterItemComponentName="getFilterItemComponentName(filterItem)"
                    :filterItemValues="getFilterItemValues(filterItem)"
                    :filterItemValuesSelected="getFilterItemValuesSelected(filterItem)"
                    :filterItemDefaultTitle="getFilterItemDefaultTitle(filterItem)"
                    :style="getFilterItemStyle(filterItem)"
                    class="reconciliation-filter__item"
                    @onSelectFilterItem="onSelectFilterItem"
                />
            </div>
        </template>
        <div
            v-if="filterItemsChips.length && !isLoading"
            class="reconciliation-filter__chips"
        >
            <div class="reconciliation-filter__chips-list">
                <Chips
                    :chips="filterItemsChips"
                    :numberDisplayedChips="0"
                    :isRemoveAll="isRemoveAllChips"
                    isRemoveAllIcon
                    @remove="onRemoveFilterChipItem"
                    @onRemoveAllChips="onRemoveAllFilterChips"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ReconciliationFilterItem from "./ReconciliationFilterItem.vue";
import Chips from "../../../layouts/components/Chips.vue";
export default {
    name: "ReconciliationFilter",
    components: {
        ReconciliationFilterItem,
        Chips,
    },
    props: {
        filterItems: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        pageType: {
            type: String,
            default: "offers",
            validator: (value) =>
                ["kits", "offers", "approved", "more", "fact", 'price'].includes(value),
        },
    },
    computed: {
        isTopFIltersVisible() {
            return ["approved", "fact"].includes(this.pageType);
        },

        filterItemsFirstRow() {
            return this.pageType !== 'price' ? this.filterItems.filter(item => ["date", "address"].includes(item.id)) : this.filterItems;
        },
        filterItemsSecondRow() {
            return this.pageType !== 'price' ? this.filterItems.filter(item => {
                return ["city_id", "supplier_id", "tags", "error_filter", "revise_filter", "client", "brand"].includes(item.id);
            }) : [];
        },
        filterItemsChips() {
            let filterItemsChips = this.filterItems.reduce(
                (filterItemsChips, filterItem) => {
                    const {
                        values = [],
                        valuesSelected = [],
                        id: filterItemId = "",
                    } = filterItem;
                    if (valuesSelected.length > 0) {
                        const valuesSelectedChips = valuesSelected.map(
                            (valueSelected) => {
                                const value = values.find(
                                    (value) =>
                                        String(value?.id) ===
                                        String(valueSelected)
                                );
                                const valueSelectedChips = {
                                    ...value,
                                    filterItemId: filterItemId,
                                };
                                return valueSelectedChips;
                            }
                        );
                        filterItemsChips.push(...valuesSelectedChips);
                    }

                    return filterItemsChips;
                },
                []
            );

            filterItemsChips = filterItemsChips.filter(
                (item) => item.filterItemId !== "date"
            );

            return filterItemsChips;
        },
        isRemoveAllChips() {
            return this.filterItemsChips.length >= 2;
        },
        filterItemsShadow() {
            const filterItemsShadowScheme = {
                offers: 2,
                kits: 1,
                approved: 7,
                more: 3,
                fact: 3,
            };

            return Array.from(
                Array(filterItemsShadowScheme[this.pageType]).keys()
            );
        },
    },
    methods: {
        getFilterItemId(filterItem = {}) {
            return String(this.getFilterItemProp(filterItem, "id") ?? "");
        },
        getFilterItemComponentName(filterItem = {}) {
            return String(
                this.getFilterItemProp(filterItem, "componentName") ?? ""
            );
        },
        getFilterItemStyle(filterItem = {}) {
            return this.getFilterItemProp(filterItem, "styleObj") ?? {};
        },
        getFilterItemValues(filterItem = {}) {
            return this.getFilterItemProp(filterItem, "values") ?? [];
        },
        getFilterItemValuesSelected(filterItem = {}) {
            return this.getFilterItemProp(filterItem, "valuesSelected") ?? [];
        },
        getFilterItemDefaultTitle(filterItem = {}) {
            return this.getFilterItemProp(filterItem, "defaultTitle") ?? [];
        },
        getFilterItemProp(filterItem = {}, propKey = "") {
            return filterItem[propKey] ?? null;
        },
        onSelectFilterItem(filterComponentItems = [], filterItemId = "") {
            this.$emit(
                "onSelectFilterItem",
                filterComponentItems,
                filterItemId
            );
        },
        onRemoveFilterChipItem(chipItem = {}) {
            const { filterItemId = "", id = "" } = chipItem;
            const filterItem =
                this.filterItems.find(
                    (filterItem) =>
                        String(filterItem?.id ?? "") === String(filterItemId)
                ) ?? {};
            let { valuesSelected = [] } = filterItem;
            const valueSelectedIndex = valuesSelected.findIndex(
                (value) => String(value) === String(id)
            );
            if (valueSelectedIndex !== -1) {
                valuesSelected.splice(valueSelectedIndex, 1);
                this.onSelectFilterItem(valuesSelected, filterItemId);
            }
        },
        onRemoveAllFilterChips() {
            const filterItemsNew = this.filterItems.map((filterItem) => {
                filterItem.valuesSelected = [];
                return filterItem;
            });

            this.$emit("onRemoveAllFilterChips", filterItemsNew);
        },
    },
};
</script>

<style lang="scss" scoped>
.reconciliation-filter {
    &__items {
        display: flex;
        flex-wrap: wrap;
    }

    &__chips {
        display: flex;
    }

    &__item {
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}
</style>
