<template>
    <div>
        <ReconciliationModalCheckAddressProgram 
            v-if="isStepContracts"
            :actionCheckAddressProgramLabel="'Далее'"
            :contracts.sync="contracts"
            @onCloseModal="onCloseModal"
            @checkAddressProgram="toStepMatchList"
        />
        <ReconciliationModalMatchList
            v-else-if="isStepMatchList"
            :matchStatus="matchStatus"
            :tableColumnsFromMatch="tableColumnsFromMatch"
            :tableColumnsToMatch="tableColumnsToMatch"
            :tableRowFromMatch="tableRowFromMatch"
            :tableRowsToMatch="tableRowsToMatch"
            :tableRowsToMatchSelected="tableRowsToMatchSelected"
            :isLoadingMatch="isLoadingMatch"
            @closeModal="onCloseModal"
            @lazyloadTableToMatch="onLazyloadTableToMatch"
            @checkRowTableToMatch="onCheckRowTableToMatch"
            @runMatch="onRunMatch"
        />
    </div>
</template>

<script>
import ReconciliationModalMatchList from './ReconciliationModalMatchList.vue';
import ReconciliationModalCheckAddressProgram from './ReconciliationModalCheckAddressProgram.vue';
export default {
    name: 'ReconciliationModalMatch',
    components: {
        ReconciliationModalCheckAddressProgram,
        ReconciliationModalMatchList
    },
    props: {
        matchStatus: {
            type: String,
            default: 'Не отправлено'
        },
        tableColumnsFromMatch: {
            type: Array,
            default: () => ([])
        },
        tableColumnsToMatch: {
            type: Array,
            default: () => ([])
        },
        tableRowFromMatch: {
            type: Object,
            default: () => ({})
        },
        tableRowsToMatch: {
            type: Array,
            default: () => ([])
        },
        tableRowsToMatchSelected: {
            type: Array,
            default: () => ([])
        },
        isLoadingMatch: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        stepIndexCurrent: 1,
        contracts: {
            1: {
                value: '', 
                isValid: true, 
                errorMessage: ''
            }
        }
    }),
    computed: {
        isStepContracts() {
            return this.stepIndexCurrent === 0;
        },
        isStepMatchList() {
            return this.stepIndexCurrent === 1;
        },
        contractNumbers() {
            return Object.values(this.contracts).map(contract => String(contract?.value ?? ''));
        }
    },
    mounted() {
        this.$emit('toStepMatchList', this.contractNumbers);
    },
    methods: {
        onCloseModal() {
            this.$emit('closeModal');
        },
        toStepMatchList() {
            this.stepIndexCurrent += 1;
            this.$emit('toStepMatchList', this.contractNumbers);
        },
        onLazyloadTableToMatch() {
            this.$emit('lazyloadTableToMatch');
        },
        onCheckRowTableToMatch(rowId = '', isChecked = false) {
            this.$emit('checkRowTableToMatch', rowId, isChecked);
        },
        onRunMatch() {
            this.$emit('runMatch');
        }
    }
}
</script>