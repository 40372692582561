import ServiceApi from '../ServiceApi';
import ServiceReconciliationFilter from './ServiceReconciliationFilter';

export default class ServiceReconciliationCheckAddressProgram {
    static _microserviceName = 'free';

    static _requestRouts = {
        validationContractNumber: '/match/entity-by-contract-number',
        checkAddressProgram: '/match/map-transactions'
    };

    static async validationContractNumber(contractNumber = '') {
        return this._validationContractNumber(contractNumber);
    }

    static async _validationContractNumber(contractNumber = '') {
        const queryParams = this._validationContractNumberBefore(contractNumber);

        try {
            await ServiceApi.get(this._microserviceName, this._requestRouts.validationContractNumber, queryParams);
            return {isValid: true, errorMessage: ''};
        } catch (error) {
            console.log(error);
            const errorMessage = this._validationContractNumberError(error);
            return contractNumber === '' ? {isValid: true, errorMessage: ''} : {isValid: false, errorMessage: String(errorMessage)};
        }
    }

    static _validationContractNumberBefore(contractNumber = '') {
        return {contractNumber: String(contractNumber)};
    }

    static _validationContractNumberError(error) {
        const {response = {}} = error;
        const {data = {}} = response;
        const {data: dataError = {}} = data;
        const {contract_number: contractErrors = []} = dataError;

        return String(contractErrors[0] ?? '');
    }

    static async checkAddressProgram(contractsValues = [], filterData = []) {
        return this._checkAddressProgram(contractsValues, filterData);
    }

    static async _checkAddressProgram(contractsValues = [], filterData = []) {
        const reqBody = this._checkAddressProgramBefore(contractsValues, filterData);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.checkAddressProgram, reqBody);
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _checkAddressProgramBefore(contractsValues = [], filterData = []) {
        const reqBody = {
            'contract_numbers': contractsValues.map(contractValue => String(contractValue)),
            ...ServiceReconciliationFilter.getFilterBefore(filterData),
        };

        return reqBody;
    }
}