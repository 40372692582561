import ServiceApi from "../ServiceApi";
export default class ServiceReconciliationMatch {
    static _microserviceName = "free";

    static _requestRouts = {
        getTableSimilarSides: '/match/similar-ap-sides',
        runMatch: '/match/map-toggle-matching',
        acceptDifference: '/match/accept-difference'
    };

    static _tableColumnsToMatchScheme = [
        {
            label: "Клиент",
            prop: "client",
            columnStyles: {
                width: "130px",
            },
        },
        {
            label: "Бренд",
            prop: "brand",
            columnStyles: {
                width: "130px",
            },
        },
        {
            label: "Город",
            prop: "city",
            columnStyles: {
                width: "130px",
            },
        },
        {
            label: "Сторона",
            prop: "side",
            columnStyles: {
                width: "80px",
            },
        },
        {
            label: "Адрес",
            prop: "address",
            columnStyles: {
                width: "500px",
            },
        },
        {
            label: "Оператор",
            prop: "supplier",
            columnStyles: {
                width: "110px",
            },
        },
        {
            label: "Тип",
            prop: "type",
            columnStyles: {
                width: "120px",
            },
        },
        {
            label: "Вид",
            prop: "kind",
            columnStyles: {
                width: "80px",
            },
        },
        {
            label: "Формат",
            prop: "format",
            columnStyles: {
                width: "80px",
            },
        },
        {
            label: "GID",
            prop: "gid",
            columnStyles: {
                width: "120px",
            },
        },
        {
            label: "Материал",
            prop: "material",
            columnStyles: {
                width: "120px",
            },
        },
        {
            label: "Сверено с предфактом",
            prop: "mapMatchStatus",
            isSlot: true,
            columnStyles: {
                width: "140px",
                padding: '0'
            },
            classes: ["map-match-status"],
        },
        {
            label: "Расстояние",
            prop: "distance",
            columnStyles: {
                width: "120px",
            },
        },
    ];

    static _tableRowToMatchScheme = {
        id: (row) => String(row?.price_id ?? ''),
        client: (row) => String(row?.entity_name ?? ''),
        brand: (row) => String(row?.brand_name ?? ''),
        city: (row) => String(row?.city?.name ?? ''),
        side: (row) => String(row?.code ?? ''),
        address: (row) => String(row?.info?.realAddress ?? ''),
        supplier: (row) => String(row?.properties?.supp_id ?? ''),
        type: (row) => String(row?.properties?.type_id ?? ''),
        kind: (row) => String(row?.properties?.kind_id ?? ''),
        format: (row) => String(row?.properties?.format_id ?? ''),
        gid: (row) => String(row?.info?.supp_item_id ?? ''),
        material: (row) => String(row?.info?.material ?? ''),
        mapMatchStatus: (row) => row?.uuid ? 'Сверено' : 'Не сверено',
        distance: (row) => String(row?.distance ?? '')
    };

    static async getTableSimilarSides(page = 0, priceId = '', contractNumbers = []) {
        return this._getTableSimilarSides(page, priceId, contractNumbers);
    }

    static async _getTableSimilarSides(page = 0, priceId = '', contractNumbers = []) {
        const { queryParams, requestBody } = this._getTableSimilarSidesBefore(page, priceId, contractNumbers);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getTableSimilarSides, requestBody, { params: queryParams });
            const tableData = this._getTableSimilarSidesAfter(res?.data?.data ?? {});

            return tableData;
        } catch (error) {
            console.log(error)
        }
    }

    static _getTableSimilarSidesBefore(page = 0, priceId = '', contractNumbers = []) {
        const queryParams = {
            'page': parseInt(page),
            'per-page': 20
        };

        const requestBody = {
            'transaction': String(priceId),
            'contract_numbers': contractNumbers.map(contractNumber => String(contractNumber))
        };

        return { queryParams, requestBody };
    }

    static _getTableSimilarSidesAfter(responseData = {}) {
        const rows = responseData?.items ?? [];
        const tableRows = rows.map(row => {
            const tableRow = Object.keys(this._tableRowToMatchScheme).reduce((tableRow, tableColumnKey) => {
                tableRow[tableColumnKey] = this._tableRowToMatchScheme[tableColumnKey](row);
                tableRow.extraData = { prices: [{ map_match_status: row?.uuid ? 1 : 2 }] };

                return tableRow;
            }, {});

            return tableRow;
        });

        const tableData = {
            pageCount: parseInt(responseData?.pageCount ?? 1),
            tableColumn: this._tableColumnsToMatchScheme,
            tableRows
        };

        return tableData;
    }

    static async runMatch(priceId = '', mapPriceId = '') {
        return this._runMatch(priceId, mapPriceId);
    }

    static async _runMatch(priceId = '', mapPriceId = '') {
        const requestBody = this._runMatchBefore(priceId, mapPriceId);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.runMatch, requestBody);

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    static _runMatchBefore(priceId = '', mapPriceId = '') {
        const requestBody = { transaction: String(priceId), map_price_id: parseInt(mapPriceId) };

        return requestBody;
    }

    static async acceptDifference(fileId = '', sheetId = '', rowId = '', action = '', attribute = '', uuid = '') {
        return this._acceptDifference(fileId, sheetId, rowId, action, attribute, uuid);
    }

    static async _acceptDifference(fileId = '', sheetId = '', rowId = '', action = '', attribute = '', uuid = '') {
        const requestBody = this._acceptDifferenceBefore(fileId, sheetId, rowId, action, attribute, uuid);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.acceptDifference, requestBody);
        } catch (error) {
            console.log(error);
        }
    }

    static _acceptDifferenceBefore(fileId = '', sheetId = '', rowId = '', action = '', attribute = '', uuid = '') {
        const requestBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(sheetId),
            row_index: parseInt(rowId),
            action: String(action),
            attribute: String(attribute),
            uuid: String(uuid)
        };

        return requestBody;
    }
}