<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="svg-icon"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.0349 3.96299C15.4994 3.7706 15.9972 3.67157 16.5 3.67157C17.0028 3.67157 17.5006 3.7706 17.9651 3.96299C18.4296 4.15539 18.8516 4.43739 19.2071 4.79289C19.5626 5.14839 19.8446 5.57043 20.037 6.03492C20.2294 6.49941 20.3284 6.99724 20.3284 7.5C20.3284 8.00275 20.2294 8.50058 20.037 8.96507C19.8446 9.42956 19.5626 9.8516 19.2071 10.2071L8.70711 20.7071C8.51957 20.8946 8.26522 21 8 21H4C3.44772 21 3 20.5523 3 20V16C3 15.7348 3.10536 15.4804 3.29289 15.2929L13.7929 4.79289C14.1484 4.43739 14.5704 4.15539 15.0349 3.96299ZM16.5 5.67157C16.2599 5.67157 16.0221 5.71886 15.8003 5.81075C15.5785 5.90264 15.3769 6.03732 15.2071 6.2071L5 16.4142V19H7.58579L17.7929 8.79289C17.9627 8.6231 18.0974 8.42154 18.1892 8.19971C18.2811 7.97787 18.3284 7.74011 18.3284 7.5C18.3284 7.25988 18.2811 7.02212 18.1892 6.80029C18.0974 6.57845 17.9627 6.37689 17.7929 6.2071C17.6231 6.03732 17.4215 5.90264 17.1997 5.81075C16.9779 5.71886 16.7401 5.67157 16.5 5.67157Z"
            fill="black"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.7929 5.79289C13.1834 5.40237 13.8166 5.40237 14.2071 5.79289L18.2071 9.79289C18.5976 10.1834 18.5976 10.8166 18.2071 11.2071C17.8166 11.5976 17.1834 11.5976 16.7929 11.2071L12.7929 7.20711C12.4024 6.81658 12.4024 6.18342 12.7929 5.79289Z"
            fill="black"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconEdit',
    props: {
        width: {
            type: Number,
            default: 18
        },
        height: {
            type: Number,
            default: 18
        },
    },
}
</script>

<style scoped>
    .svg-icon path {
        fill: currentColor;
    }
</style>
