<template>
    <BaseModal 
        :modalWidth="'1020px'"
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="reconciliation-modal-match-list__body">
                <div class="reconciliation-modal-match-list__section">
                    <div class="reconciliation-modal-match-list__title">
                        Ручная сверка с Oohdesk
                    </div>
                </div>
                <div class="reconciliation-modal-match-list__section">
                    <div class="reconciliation-modal-match-list__sub-title">
                        {{ matchStatus }}
                    </div>
                </div>
                <div class="reconciliation-modal-match-list__section">
                    <ReconciliationTable
                        :columns="tableColumnsFromMatch"
                        :columnsShowed="tableColumnsFromMatchShowed"
                        :rows="tableRowsFromMatch"
                    />
                </div>
                <div class="reconciliation-modal-match-list__section">
                    <div class="reconciliation-modal-match-list__sub-title">
                        Стороны в Oohdesk
                    </div>
                </div>
                <div class="reconciliation-modal-match-list__section">
                    <ReconciliationTable
                        :columns="tableColumnsToMatch"
                        :columnsShowed="tableColumnsToMatch"
                        :rows="tableRowsToMatch"
                        :tableRowsSelectedIds="tableRowsToMatchSelected"
                        :isCanCheck="true"
                        :isCanCheckAll="false"
                        :isLoading="isLoadingMatch"
                        @onLazyload="onLazyloadTableToMatch"
                        @onCheckRow="onCheckRowTableToMatch"
                    />
                </div>
                <div class="reconciliation-modal-match-list__actions">
                    <div class="reconciliation-modal-match-list__action">
                        <BaseButton
                            view="border"
                            @click="onCloseModal"
                        >
                            Закрыть
                        </BaseButton>
                    </div>
                    <div class="reconciliation-modal-match-list__action">
                        <BaseButton
                            :disabled="!isRunMatch"
                            view="secondary"
                            @click="onRunMatch"
                        >
                            Далее
                        </BaseButton>
                    </div>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../../Base/BaseModal.vue';
import BaseButton from '../../Base/BaseButton.vue';
import ReconciliationTable from '../ReconciliationTable/ReconciliationTable.vue';
export default {
    name: 'ReconciliationModalMatchList',
    components: { 
        BaseModal,
        BaseButton,
        ReconciliationTable
    },
    props: {
        matchStatus: {
            type: String,
            default: 'Не отправлено'
        },
        tableColumnsFromMatch: {
            type: Array,
            default: () => ([])
        },
        tableColumnsToMatch: {
            type: Array,
            default: () => ([])
        },
        tableRowFromMatch: {
            type: Object,
            default: () => ({})
        },
        tableRowsToMatch: {
            type: Array,
            default: () => ([])
        },
        tableRowsToMatchSelected: {
            type: Array,
            default: () => ([])
        },
        isLoadingMatch: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        tableColumnsFromMatchShowed() {
            return this.tableColumnsFromMatch.filter(tableColumn => String(tableColumn?.prop ?? '') !== 'actionsApproved');
        },
        tableRowsFromMatch() {
            return [this.tableRowFromMatch];
        },
        isRunMatch() {
            return this.tableRowsToMatchSelected.length > 0;
        }
    },  
    methods: {
        onCloseModal() {
            this.$emit('closeModal');
        },
        onLazyloadTableToMatch() {
            this.$emit('lazyloadTableToMatch');
        },
        onCheckRowTableToMatch(checkRowData = {}) {
            this.$emit('checkRowTableToMatch', String(checkRowData?.row?.id ?? ''), Boolean(checkRowData?.rowIsSelected ?? false));
        },
        onRunMatch() {
            this.$emit('runMatch');
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-modal-match-list {
        &__section {
            margin-bottom: 20px;
        }

        &__title {
            font-size: 20px;
            font-weight: 500;
        }

        &__sub-title {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 10px;
        }

        &__actions {
            display: flex;
            justify-content: end;
        }

        &__action {
            position: relative;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
</style>