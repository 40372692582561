import ServiceApi from '../ServiceApi';
import UtilDate from '../../utils/UtilDate';
import UtilLocalStorage from '../../utils/UtilLocalStorage';

export default class ServiceReconciliationFilter {
    static _microserviceName = 'free';

    static _requestRouts = {
        getFilter: {
            offers: '/new_map/offer-filter',
            kits: '/new_map/set-filter',
            more: '/new_map/offer-side-filter',
            approved: '/new_map/approve-filter',
            fact: '/new_map/done-filter'
        }
    };

    static _filterItemsScheme = {
        offers: ['city_id', 'creator_id', 'tags', 'name'],
        kits: ['city_id', 'name'],
        approved: ['date', 'city_id', 'supplier_id', 'tags', 'address', 'error_filter', 'revise_filter', 'client', 'brand'],
        fact: ['date', 'city_id', 'supplier_id', 'tags', 'address'],
        more: ['date', 'city_id', 'supplier_id', 'address']
    };

    static _filterItemsTotalScheme = {
        'name': {
            id: 'name',
            componentName: 'BaseTextInput',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Поиск по названию',
            getValuesAfter: (value = '') => {
                return value === '' ? [] : [{id: String(value), value: String(value)}];
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                const valueSelected = valuesSelected[0] ?? '';
                return String(valueSelected);
            },
            getValuesSelectedAfter: (valueSelected = '', values = []) => {
                const value = values[0] ?? {};
                const {id: valueId = ''} = value;
                return (String(valueSelected) === '' || String(valueSelected) !== String(valueId)) ? [] : [String(valueSelected)];
            },
            styleObj: {
                'flex-grow': 1
            }
        },
        'address': {
            id: 'address',
            componentName: 'BaseTextInput',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Поиск по адресу и GID',
            getValuesAfter: (value = '') => {
                return value === '' ? [] : [{id: String(value), value: String(value)}];
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                const valueSelected = valuesSelected[0] ?? '';
                return String(valueSelected);
            },
            getValuesSelectedAfter: (valueSelected = '', values = []) => {
                const value = values[0] ?? {};
                const {id: valueId = ''} = value;
                return (String(valueSelected) === '' || String(valueSelected) !== String(valueId)) ? [] : [String(valueSelected)];
            },
            styleObj: {
                'width': '510px'
            }
        },
        'date': {
            id: 'date',
            componentName: 'DatePickerDropdown',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Даты',
            getValuesAfter: (values) => {
                return values.map(value => ({id: String(value), value: String(value)}))
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                const valuesSelectedFiltered = valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
                const valuesSelectedAfter = valuesSelected.length > 0 ? valuesSelectedFiltered : [UtilDate.getNearDate(valuesIds, true)];
                return values.length > 0 ? valuesSelectedAfter : [];
            },
            styleObj: {}
        },
        'supplier_id': {
            id: 'supplier_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Операторы',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'city_id': {
            id: 'city_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Города',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'creator_id': {
            id: 'creator_id',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Менеджеры',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'tags': {
            id: 'tags',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Тэги',
            getValuesAfter: (values) => {
                return values.map(value => ({id: String(value), value: String(value)}))
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'error_filter': {
            id: 'error_filter',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Ошибки',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'revise_filter': {
            id: 'revise_filter',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Сверки',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'brand': {
            id: 'brand',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Бренд',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
        'client': {
            id: 'client',
            componentName: 'BaseSelectMultipleV2',
            values: [],
            valuesSelected: [],
            defaultTitle: 'Клиент',
            getValuesAfter: (values) => {
                return values;
            },
            getValuesSelectedBefore: (valuesSelected = []) => {
                return valuesSelected;
            },
            getValuesSelectedAfter: (valuesSelected = [], values = []) => {
                const valuesIds = values.map(value => String(value?.id ?? ''));
                return valuesSelected.filter(valueSelected => valuesIds.includes(String(valueSelected)));
            },
            styleObj: {}
        },
    };

    static _localStorageFilterSelected = 'reconciliationsFilterSelected';

    static _getFilterSelected() {
        return UtilLocalStorage.getData(this._localStorageFilterSelected) ?? {};
    }

    static getFilterSelectedByPageType(pageType = '') {
        const filterSelected = this._getFilterSelected();

        return filterSelected[pageType] ?? [];
    }

    static setFilterSelectedByPageType(pageType = '', filterDataSelected = []) {
        const filterSelected = this._getFilterSelected();

        UtilLocalStorage.setData(this._localStorageFilterSelected, { ...filterSelected, [pageType]: filterDataSelected });
    }

    static async getFilter(pageType = '', filterData = [], kitId = '', actionAfter = () => {}, actionError = () => {}) {
        await this._getFilter(pageType, filterData, kitId, actionAfter, actionError);
    }

    static async _getFilter(pageType = '', filterData = [], kitId = '', actionAfter = () => {}, actionError = () => {}) {
        const reqBody = this._getFilterBefore(filterData, kitId);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getFilter[pageType] ?? '', reqBody)
            const {data = {}} = res;
            const {data: resBody = {}} = data;
            const filterDataBefore = reqBody?.filter ?? {};
            const filterItems = this._getFilterAfter(resBody, filterDataBefore, pageType);
            actionAfter(filterItems);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static getFilterBefore(filterData = [], kitId = '') {
        return this._getFilterBefore(filterData, kitId);
    }

    static _getFilterBefore(filterData = [], kitId = '') {
        const reqBody = filterData.reduce((reqBody, filterDataItem) => {
            const {id = '', valuesSelected = []} = filterDataItem;
            const {getValuesSelectedBefore = () => {}} = this._filterItemsTotalScheme[id] ?? {};
            reqBody[id] = getValuesSelectedBefore(valuesSelected);

            return reqBody;
        }, {});

        return kitId === '' ? {filter: reqBody} : {price_group_id: parseInt(kitId), filter: reqBody};
    }

    static _getFilterAfter(resBody = {}, reqBody = {}, pageType = '') {
        const filtersKeys = this._filterItemsScheme[pageType];

        const filterItems = filtersKeys.map(filterItemId => {
            const {getValuesAfter = () => {}, getValuesSelectedAfter = () => {}} = this._filterItemsTotalScheme[filterItemId];
            const values = getValuesAfter(filterItemId === 'name' ? reqBody[filterItemId] : resBody[filterItemId]);
            const valuesSelected = getValuesSelectedAfter(reqBody[filterItemId], values);
            const filterItem = {
                ...this._filterItemsTotalScheme[filterItemId],
                values,
                valuesSelected,
            };

            return filterItem;
        });

        return filterItems;
    }
}
